<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}" :initial-data="initialData">

        <div class="row col-12">
            <validated-input class="col-12" label="Company Overview Para 1" name="Company Overview Para 1" v-model="model.company_overview_para_1"
                         :rules="rules.company_overview_para_1" :disabled="loading"/>
        </div>

        <div class="row col-12">
            <validated-textarea class="col-12" label="Company Overview Para 2" v-model="model.company_overview_para_2"
                         :rules="rules.company_overview_para_2" :disabled="loading"/>

        </div>

        <div class="row col-12">
            <validated-input class="col-6" label="Who we are para 1" name="Who we are para 1" v-model="model.who_we_are_para_1"
                         :rules="rules.who_we_are_para_1" :disabled="loading"/>

            <validated-input class="col-6" label="Who we are para 2" v-model="model.who_we_are_para_2"
                         :rules="rules.who_we_are_para_2" :disabled="loading"/>
        </div>

        <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                             :rules="rules.disabled" :disabled="loading"/>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'EditAboutUs',
    computed : { ...mapGetters(['currentUser']) },
    props    : { initialData : { type : Object } },
    data () {
        return {
            addUrl : urls.admin.event.about_us.addEdit,
            rules  : {
                company_overview_para_1 : {
                    required : true
                },
                company_overview_para_2 : {
                    required : false
                },
                who_we_are_para_1 : {
                    required : true
                },
                who_we_are_para_2 : {
                    required : false
                },
                disabled : {
                    required : false
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Edited About Us..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
