<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'ABOUT US'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.aboutUsAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
             <template #disabled="{rowData}">
                <p v-if="rowData.disabled">Yes</p>
                <p v-else>No</p>
            </template>
             <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add About Us" ref="aboutUsAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddAboutUs @success="formSuccess"></AddAboutUs>
        </modal>

        <modal title="Edit About Us" ref="aboutUsEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditAboutUs :initial-data="editingItem" @success="formSuccess"></EditAboutUs>
        </modal>

        <delete-modal ref="aboutUsDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the About Us <b v-html="deletingItem && deletingItem.about_us"></b>. Are you sure
                ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddAboutUs from './AddAboutUs';
import EditAboutUs from './EditAboutUs';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'AboutUs',
    components : { AddAboutUs, EditAboutUs },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            prefix       : '',
            listUrl      : urls.admin.event.about_us.list,
            deleteUrl    : urls.admin.event.about_us.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'company_overview_para_1',
                    sortField  : 'company_overview_para_1',
                    title      : 'Company Overview Paragraph 1',
                    titleClass : ''
                },
                // {
                //     name       : 'company_overview_para_2',
                //     sortField  : 'company_overview_para_2',
                //     title      : 'Company Overview Paragraph 2',
                //     titleClass : ''
                // },
                {
                    name       : 'who_we_are_para_1',
                    sortField  : 'who_we_are_para_1',
                    title      : 'Who we are Paragraph 1',
                    titleClass : ''
                },
                {
                    name       : 'who_we_are_para_2',
                    sortField  : 'who_we_are_para_2',
                    title      : 'Who we are Paragraph 2',
                    titleClass : ''
                },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.aboutUsAddModal.close();
            refs.aboutUsEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.aboutUsEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.aboutUsDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted About Us..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.aboutUsDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            const key = {
                api_key : that.currentUser.api_key
            };
            const data = {
                ...key,
                ...item
            };
            axios.form(urls.admin.event.about_us.addEdit, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated About Us..!', 'Success',
                        {
                            type : 'success'
                        });
                    that.$refs.table.refreshTable();
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
