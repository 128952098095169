import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=cc4d1c46&scoped=true&"
import script from "./AboutUs.vue?vue&type=script&lang=js&"
export * from "./AboutUs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4d1c46",
  null
  
)

export default component.exports